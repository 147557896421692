import { EnvironmentConfig } from '../app/models/environment-config.model';

export const environment: EnvironmentConfig = {
  production: false,
  maintenanceMode: 'false',
  ccEmails: 'EDU_Test_Supplier_1@outlook.com;EDU_Test_Port_1@outlook.com',
  clientId: '314fa6f4-5262-40b1-8cc9-99424283a4e0',
  apiClientId: '13fd2570-b1f1-445c-b6c2-016e6b513342',
  dataUploadApiEndpoint: 'https://api-preukhodataupload.admiralty.co.uk',
  authorityDomain: 'identity-test.admiralty.co.uk',
  baseAuthorityDomain: 'https://identity-test.admiralty.co.uk/c99f6ac6-cd6a-40c9-985e-3e2f94eac754',
  b2cScopeBaseUrl: 'https://MGIAIDTESTB2C.onmicrosoft.com',
  elasticApmConfig: {
    serviceName: 'External Data Upload UI',
    environment: 'Pre',
    serverUrl: 'https://ukho-nonlive-elastic.apm.uksouth.azure.elastic-cloud.com:9243',
    serviceVersion: '0.0.0',
    logLevel: 'info',
    distributedTracingOrigins: [
      'https://identity-test.admiralty.co.uk',
      'https://api-preukhodataupload.admiralty.co.uk',
      'https://eduapppresalandingzone.blob.core.windows.net'
    ]
  }
};
